@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// GLOBALBOT ANIMATIONS
// Every class here begin with "g-a", so if you hare stupid and not understand I'll explain, THIS MEANS "GLOBALBOT ANIMATIONS".

$times: (
  '1': 1s,
  '2': 2s,
  '3': 3s,
  '4': 4s,
  '5': 5s,
  '6': 6s,
  '7': 7s,
  '8': 8s,
  '9': 9s,
  '10': 10s
);

@each $key, $value in $times {
  // SPIN
  .g-a-spin-#{$key}:hover {
    -webkit-animation:spin #{$value} linear infinite;
    -moz-animation:spin #{$value} linear infinite;
    -o-transition: spin #{$value} linear infinite;
    animation: spin #{$value} linear infinite;
  }
  // SPIN FOR BUTTONS
  .g-a-spin-#{$key}-btn:hover > span > i {
    -webkit-animation:spin #{$value} linear infinite;
    -moz-animation:spin #{$value} linear infinite;
    -o-transition: spin #{$value} linear infinite;
    animation: spin #{$value} linear infinite;
  }
}

// KEY FRAMES
@-moz-keyframes spin {
  0% { -moz-transform: rotate(360deg); }
  25% { -moz-transform: rotate(360deg); }
  50% { -moz-transform: rotate(360deg); }
  75% { -moz-transform: rotate(360deg); }
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  25% { -webkit-transform: rotate(180deg); }
  50% { -webkit-transform: rotate(180deg); }
  75% { -webkit-transform: rotate(360deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% {-webkit-transform: rotate(0deg);transform:rotate(0deg);}
  25% {-webkit-transform: rotate(180deg);transform:rotate(180deg);}
  50% {-webkit-transform: rotate(180deg);transform:rotate(180deg);}
  75% {-webkit-transform: rotate(360deg);transform:rotate(360deg);}
  100% {-webkit-transform: rotate(360deg);transform:rotate(360deg);}
}

.g-auto-hide > div:last-child {
  display: none;
}
.g-auto-hide:hover > div:first-child {
  display: none;
}
.g-auto-hide:hover > div:last-child {
  display: inherit;
}
