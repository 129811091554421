@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
$colors: (
  'E6E6E6': #e6e6e6,
  '737373': #737373,
  'F5F5F5': #f5f5f5,
  '424242': #424242,
  '2F2F2F': #2f2f2f,
  'FCFCFC': #fcfcfc,
  '5D5D5D': #5d5d5d,
  '979797': #979797,
  'C4C4C4': #c4c4c4,
  'accent': #dadada,
  'F8F8F8': #f8f8f8,
  '454545': #454545,
  'AAAAAA': #aaaaaa,
  'EEEEEE': #EEEEEE,
  'B3B3B3': #B3B3B3,
  '2B2B2B': #2B2B2B,
  'FF6600': #FF6600,
  'FFFFFF': #FFFFFF,
  'light': #F5F5F5,
  'BBBBBB': #BBBBBB,
  '969696': #969696,
  'F5F5F9': #F5F5F9,
  'DADADA': #DADADA,
  'CCCCCC': #CCCCCC
);

$sizePixel: (
  '0': 0,
  '2': 2px,
  '3': 3px,
  '4': 4px,
  '5': 5px,
  '6': 6px,
  '8': 8px,
  '9': 9px,
  '10': 10px,
  '11': 11px,
  '13': 13px,
  '15': 15px,
  '16': 16px,
  '18': 18px,
  '20': 20px,
  '22': 22px,
  '25': 25px,
  '27': 27px,
  '30': 30px,
  '32': 32px,
  '34': 34px,
  '38': 38px,
  '40': 40px,
  '42': 42px,
  '44': 44px,
  '50': 50px,
  '54': 54px,
  '60': 60px,
  '65': 65px,
  '67': 67px,
  '70': 70px,
  '75': 75px,
  '79':79px,
  '80': 80px,
  '82': 82px,
  '87': 87px,
  '90': 90px,
  '100': 100px,
  '110': 110px,
  '115': 115px,
  '120': 120px,
  '147': 147px,
  '160': 160px,
  '200': 200px,
  '267': 267px,
  '300': 300px,
  '360': 360px,
  '373': 373px,
  '400': 400px,
  '420': 420px,
  '485': 485px,
  '500': 500px,
  '580': 580px,
  '600': 600px,
  '720': 720px,
  '800': 800px,
  '854': 854px,
  '900': 900px,
  '1100': 1100px,
  '1280': 1280px
);

$bg-white: #f8f8f8;
$bg-dark: #2b2b2b;
$light: #dadada;
$white: #ffffff;
$black: #000000;
$dark: #333333;
$border-radius: 10px;

// FONT SIZE
@for $num from 11 to 51  {
  .g-font-#{$num} {
    font-size: #{$num}px;
  }
}

// LINE HEIGHT
@for $num from 10 to 34 {
  .g-line-#{$num} {
    line-height: #{$num}px;
  }
}

// OPACITY
@for $num from 1 to 11 {
  .g-opacity-#{$num} {
    opacity: #{$num/10} !important;
  }
}

@each $key, $value in $colors {
  // BORDER BOTTOM
  .g-bb-#{$key} {
    border-bottom: 1px solid $value;
  }

  // BORDER TOP
  .g-bt-#{$key} {
    border-top: 1px solid $value;
  }

  // BORDER LEFT
  .g-bl-#{$key} {
    border-left: 1px solid $value;
  }

  // BORDER RIGHT
  .g-br-#{$key} {
    border-right: 1px solid $value;
  }

  // BORDER
  .g-border-#{$key} {
    border: 1px solid $value;
  }

  .g-border2-#{$key} {
    border: 2px solid $value;
  }

  // BACKGROUND
  .g-bg-#{$key} {
    background: $value;
  }

  // TEXT COLOR
  .g-color-#{$key} {
    color: $value;
  }
}

@for $num from 1 to 100 {
  $percent: 1% * $num;
  // WIDTH PERCENT
  .g-w-per-#{$num} {
    width: $percent;
  }

  // MAX-WIDTH PERCENT
  .g-maxw-per-#{$num} {
    max-width: $percent;
  }

  //  MIN-WIDTH PERCENT
  .g-minw-per-#{$num} {
    min-width: $percent;
  }

  //  HEIGHT PERCENT
  .g-h-per-#{$num} {
    height: $percent;
  }

  //  MAX-HEIGHT PERCENT
  .g-maxh-per-#{$num} {
    max-height: $percent;
  }

  //  MIN-HEIGHT PERCENT
  .g-minh-per-#{$num} {
    min-height: $percent;
  }
}

@each $key, $value in $sizePixel {
  // WIDTH PIXEL
  .g-w-#{$key} {
    width: $value!important;
  }

  // MiN WIDTH PIXEL
  .g-min-w-#{$key} {
    min-width: $value!important;
  }

  // MAX WIDTH PIXEL
  .g-max-w-#{$key} {
    max-width: $value!important;
  }

  // HEIGHT PIXEL
  .g-h-#{$key} {
    height: $value;
  }

  // MIN HEIGHT PIXEL
  .g-min-h-#{$key} {
    min-height: $value;
  }

  // PADDING
  .g-ma-#{$key} {
    margin: $value;
  }

  // MARGIN HORIZONTAL
  .g-mh-#{$key} {
    margin-left: $value;
    margin-right: $value;
  }

  // MARGIN VERTICAL
  .g-mv-#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }

  // MARGIN TOP
  .g-mt-#{$key} {
    margin-top: $value;
  }

  // MARGIN TOP NEGATIVE
  .g-mt-n#{$key} {
    margin-top: -$value;
  }

  // MARGIN BOTTOM
  .g-mb-#{$key} {
    margin-bottom: $value;
  }

  // MARGIN BOTTOM NEGATIVE
  .g-mb-n#{$key} {
    margin-bottom: -$value;
  }

  //  MARGIN LEFT
  .g-ml-#{$key} {
    margin-left: $value;
  }

  //  MARGIN LEFT NEGATIVE
  .g-ml-n#{$key} {
    margin-left: -$value;
  }

  //  MARGIN RIGHT
  .g-mr-#{$key} {
    margin-right: $value;
  }

  //  MARGIN RIGHT NEGATIVe
  .g-mr-n#{$key} {
    margin-right: -$value;
  }

  // PADDING
  .g-pa-#{$key} {
    padding: $value;
  }

  // PADDING TOP
  .g-pt-#{$key} {
    padding-top: $value;
  }

  // PADDING BOTTOM
  .g-pb-#{$key} {
    padding-bottom: $value;
  }

  // PADDING LEFT
  .g-pl-#{$key} {
    padding-left: $value;
  }

  // PADDING RIGHT
  .g-pr-#{$key} {
    padding-right: $value;
  }

  // PADDING HORIZONTAL
  .g-ph-#{$key} {
    padding-left: $value;
    padding-right: $value;
  }

  // PADDING VERTICAL
  .g-pv-#{$key} {
    padding-top: $value;
    padding-bottom: $value;
  }

  // BORER RADIUS PIXEL
  .g-rounded-p#{$key} {
    border-radius: $value;
  }

  // BORDER RADIUS TOP LEFT
  .g-rounded-tl-p#{key} {
    border-top-left-radius: $value;
  }

  // BORDER RADIUS TOP RIGHT
  .g-rounded-tr-p#{key} {
    border-top-right-radius: $value;
  }

  // BORDER RADIUS BOTTOMN LEFT
  .g-rounded-bl-p#{key} {
    border-bottom-left-radius: $value;
  }

  // BORDER RADIUS BOTTOMN RIGHT
  .g-rounded-br-p#{key} {
    border-bottom-right-radius: $value;
  }
}

// TOP LEFT RIGHT BOTTOM
@for $num from 0 to 100 {
  .g-top-#{$num}{
    top: #{$num}px;
  }
  .g-bottom-#{$num}{
    bottom: #{$num}px;
  }
  .g-right-#{$num}{
    right: #{$num}px;
  }
  .g-left-#{$num}{
    left: #{$num}px;
  }
}

// Z-INDEX
@for $num from 1 to 100 {
  .g-zindex-#{$num} {
    z-index: $num;
  }
}

.g-decorationNone{
  text-decoration: none !important;
}

// GLOBALBOT BACKGROUNDS

.g-bg-white {
  background: $bg-white!important;
}
.g-bg-dark {
  background: $bg-dark!important;
}

// Q-CARD DEFINITION
.q-card {
  background: $white;
  border-radius: $border-radius;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.q-card--dark {
  background: $dark;
}
.q-dialog__inner > div {
  border-radius: 10px;
}
.q-drawer {
  background: $bg-white;
}
.q-drawer--dark {
  background: $bg-dark;
}

// Q-BTN
.q-btn .q-icon {
  font-size: 1.4em;
}

// SCROLL AREA
.q-scrollarea__content {
  width: 100%;
}

// TABLE
.q-table__container {
  background: transparent;
}
.q-table--dark tbody tr:hover {
  background: $dark;
}
.q-table > thead > tr > th {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.87;
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}
.g-column-fixed-left {
  position: sticky!important;
  left: 0;
  z-index: 1;
}
.q-table .g-column-fixed-left-background {
  background: $bg-white!important;
}
.q-table--dark .g-column-fixed-left-background {
  background: $bg-dark!important;
}
.g-column-fixed-right {
  position: sticky!important;
  right: 0;
  z-index: 1;
}
.q-table .g-column-fixed-right-background {
  background: $bg-white!important;
}
.q-table--dark .g-column-fixed-right-background {
  background: $bg-dark!important;
}
.q-table__top{
  padding: 0 !important;
}

// BYPASS CHROME INPUT AUTOFILL
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #333333 inset !important;
}
input:-webkit-autofill{
  -webkit-text-fill-color: white;
}
.inputFormLogin {
  margin: 5px;
}

// Q-HOVERABLE
body.desktop .q-list .q-hoverable:hover > .q-focus-helper {
  background: currentColor;
  opacity: 0;
}

// Q-FIELD
.q-field--filled.q-field--dark .q-field__control, .q-field--filled.q-field--dark .q-field__control:before {
  background: $dark
}
.q-field--dark .q-dark.q-chip{
  background: #5d5d5d;
}

// Q-ITEM
.q-item.q-router-link--active, .q-item--active {
  color: inherit;
}
.q-item.q-item-type.text-secondary {
  color: $black!important;
}
.q-item.q-item-type.q-item--dark.text-secondary {
  color: $white!important;
}

// EMOJI MART
.emoji-mart .emoji-mart-search input {
  margin-top: 6px;
  border: none!important;
  background: #f5f5f5;
}
.bg-dark > .emoji-mart .emoji-mart-search input {
  background: #454545;
  color: $white;
}
.emoji-mart .emoji-mart-category-label {
  background: $white;
}
.bg-dark > .emoji-mart .emoji-mart-category-label {
  background: $dark;
  color: $white
}
.emoji-mart .emoji-mart-category .emoji-mart-emoji:hover:before, .emoji-mart-emoji-selected:before {
  background: #f5f5f5;
}
.bg-dark .emoji-mart .emoji-mart-category .emoji-mart-emoji:hover:before, .emoji-mart-emoji-selected:before {
  background: #454545;
}
.emoji-mart .emoji-mart-anchor {
  color: #222427;
}
.bg-dark .emoji-mart .emoji-mart-anchor {
  color: $white;
}
.emoji-mart .emoji-mart-anchor:hover, .emoji-mart-anchor-selected {
  color: #ff6600;
}

.emoji-mart > ::-webkit-scrollbar {
  width: 13px;
}

.emoji-mart > ::-webkit-scrollbar-track {
  border-radius: 10px;
}
.emoji-mart > ::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  border: 3px solid $white;
}
.emoji-mart:hover > ::-webkit-scrollbar-thumb {
  background: $light;
}
.emoji-mart > .emoji-mart-scroll {
  overflow: auto !important;
}
.emoji-mart > ::-webkit-scrollbar {
  width: 13px;
}
.emoji-mart > ::-webkit-scrollbar-track {
  border-radius: 10px;
}
.bg-dark .emoji-mart > ::-webkit-scrollbar-thumb {
  background: #2b2b2b;
  border-radius: 10px;
  border: 3px solid $dark;
}
.bg-dark .emoji-mart:hover > ::-webkit-scrollbar-thumb {
  background: #424242;
}

// EMOJIS
.g-emoji-small {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}

.g-emoji-medium {
  width: 48px;
  height: 48px;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}

.g-emoji-display .g-emoji-medium {
  width: 18px;
  height: 18px;
}

// Q-SELECT
.q-select--multiple.q-field--dense.q-select--with-chips .q-field__native.row.items-center {
  flex-wrap: nowrap;
  overflow: hidden;
  min-height: 42px;
}
.q-field--standard .q-field__control:before {
  border-bottom-color: $light;
}
.q-field--dark .q-field__control:before {
  border-bottom-color: #454545;
}
.q-field__label {
  font-size: 14px;
}

// Q-SEPARATOR
// If you need some padding on this element use the g-mh-# class to resolve.
.q-separator {
  background: $light;
}
.q-separator--dark {
  background: #454545;
}

// Q-CHECKBOX
.q-checkbox__inner {
  font-size: 32px;
}

// SCROLL
// Global light
::-webkit-scrollbar {
  background: #f8f8f8;
  width: 13px;
}
::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 10px;
  border: 3px solid #f8f8f8;
}
.g-scroll-dark ::-webkit-scrollbar {
  background: #2b2b2b;
}
.g-scroll-dark ::-webkit-scrollbar-thumb {
  background: #454545;
  border: 3px solid #2b2b2b;
}
// SCROLL FOR Q-MENU
.q-menu::-webkit-scrollbar {
  background: #ffffff;
  border-radius: 10px;
}
.q-menu::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 3px solid #ffffff;
}
.g-scroll-dark .q-menu::-webkit-scrollbar {
  background: $dark;
}
.g-scroll-dark .q-menu::-webkit-scrollbar-thumb {
  background: #454545;
  border: 3px solid $dark;
}

// Q-TOOLTIP
.q-tooltip--style {
  font-size: 12px;
}

// Q-LIST
.q-list > .q-item:hover {
  background: $light;
}
.q-list.q-list--dark > .q-item:hover {
  background: $dark;
}
